@tailwind base;
@tailwind components;
@tailwind utilities;

.poppins-font {
  font-family: 'Poppins', sans-serif;
}

.inter-font {
  font-family: 'Inter', sans-serif;
}

.button-primary {
  background: linear-gradient(180deg, #F0BC01 0%, #ED8B01 100%);
  text-align: center;
  color: theme('colors.title-brown')
}

.light-gray-border {
  border-color: theme('colors.border-light-gray')
}

.button-instagram {
  background: linear-gradient(90deg, #FFC738 0%, #EB00FF 100%);
}